<script setup>
const authUser = useAuthUser()
const openDepositPopup = useOpenDepositPopup()
</script>

<template>
  <div class="w-full flex items-center justify-between font-inter">
    <div class="flex items-center space-x-5">
      <RouterLink to="/" class="">
        <img src="/images/logo.svg" class="hidden md:block max-w-[11.938rem] max-h-[2.5rem] w-full h-full object-cover" alt="">
        <img src="/images/mobile_logo.svg" class="md:hidden" alt="">
      </RouterLink>
    </div>

    <div class="flex items-center space-x-5">
      <button
        v-if="!authUser.username"
        @click="$wallet.connectWallet()"
        class="text-white text-sm font-inter font-bold bg-primary text-center md:w-[9.813rem] w-[9.113rem] h-[2.5rem]  hover:bg-opacity-75 duration-300 rounded-[0.388rem] md:rounded-[0.188rem]"
      >Connect Wallet</button>

      <template v-else>
        <div class="sm:flex justify-center hidden">
          <div class="min-w-[10rem]">
            <div class="font-inter text-sm font-medium flex justify-between gap-x-5">
              <span>Wallet balance</span>
              <span class="font-bold">$ {{ Number($wallet.USDCBalance).toFixed(2) }}</span>
            </div>

            <hr>

            <div class="font-inter text-sm font-medium flex justify-between gap-x-5">
              <span>In-game balance</span>
              <span class="font-bold">$ {{ Number(authUser.balance).toFixed(2) }}</span>
            </div>
          </div>
        </div>

        <button
          @click="openDepositPopup = true"
          class="text-white text-sm font-inter font-bold bg-primary text-center md:w-[9.813rem] w-[9.113rem] h-[2.5rem]  hover:bg-opacity-75 duration-300 rounded-[0.388rem] md:rounded-[0.188rem] hover:shadow-primary"
        >Deposit</button>
      </template>
    </div>
  </div>

  <div class="flex justify-center mt-4 sm:hidden">
    <div class="min-w-[10rem]">
      <div class="font-inter text-sm font-medium flex justify-between gap-x-5">
        <span>Wallet balance</span>
        <span class="font-bold">$ {{ Number($wallet.USDCBalance).toFixed(2) }}</span>
      </div>

      <hr>

      <div class="font-inter text-sm font-medium flex justify-between gap-x-5">
        <span>In-game balance</span>
        <span class="font-bold">$ {{ Number(authUser.balance).toFixed(2) }}</span>
      </div>
    </div>
  </div>
</template>


