<script setup>
const nuxtApp = useNuxtApp()

nuxtApp.hook('page:finish', () => {
  setTimeout(async() => {
    await nuxtApp.$wallet.startup()
  }, 1_000)
})
</script>

<template>
  <div class=" w-full bg-[#11161C] h-full text-white overflow-hidden">
    <div class=" w-full px-4 xl:px-0 absolute z-50 flex py-5">
      <div class=" mx-auto md:pt-5 max-w-[87.875rem] w-full">
        <PartialsHeader />
      </div>
    </div>

    <div class=" w-full">
      <slot />
    </div>
    <!-- <Footer /> -->
  </div>
</template>